import {PersistStorage} from "zustand/middleware";
import {deleteCookie, getCookie, setCookie} from "cookies-next";
import {UserInfoCookieInterface, UserInfoInterface} from "@/lib/type/login-register.type";
import {ProvincesInterface} from "@/lib/type/address.type";
import provinces from "@/lib/json/provinces.json";

// 创建一个自定义存储
export const cookiesStorage = <T>({maxAge = 60 * 60 * 24 * 7}: { maxAge?: number }) => {
    return {
        getItem: async (name) => {
            const value = getCookie(name);
            return value ? JSON.parse(value) : null;
        },
        setItem: async (name, value) => {
            setCookie(name, value, {maxAge});
        },
        removeItem: async (name) => {
            deleteCookie(name);
        }
    } as PersistStorage<T>;
};

// 解析userInfoCookie

export const analysisUserInfoCookie = (cookie: UserInfoCookieInterface | undefined): UserInfoInterface | undefined => {
    if (!cookie) return cookie;
    let userInfo: UserInfoInterface | undefined = undefined;
    if (cookie) {
        const value = typeof cookie?.value === "string" ? JSON.parse(cookie.value) : cookie?.value;
        userInfo = value?.state?.userInfo;
    }
    return userInfo;
};


export const isDev = process.env.NODE_ENV === "development";


// 传入一个中国手机号码，中间四位变为*号
export const hidePhone = (phone?: string) => {
    if (!phone) return phone;
    return phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
};

// 传入一个字符串，复制到剪切板
export const copyToClipboard = (text?: string) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
};

// 创建一个input file type
export const createInputFile = (accept: string) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = accept;
    return input;
};


// 睡眠
export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

// 下载
export const download = (url: string, fileName?: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "";
    a.click();
};

// 验证码错误
export const CodeError = "验证码错误";

export const MOBILE_REG = /^[1][3456789][0-9]{9}$/;

// 传入一个code找到省市区中的数据

export function findArea(targetValue: string): ProvincesInterface[][] {
    if (!targetValue) return [];
    const nodes = provinces as unknown as ProvincesInterface[];
    let result: ProvincesInterface[][] = [];

    function dfs(node: ProvincesInterface, path: ProvincesInterface[]): void {
        if (node.value === targetValue) {
            result.push([...path, node]);
        } else {
            for (let child of node.children) {
                dfs(child, [...path, node]);
            }
        }
    }

    for (let node of nodes) {
        dfs(node, []);
    }

    return result.sort((a, b) => a.length - b.length);
}
// 防抖函数
export function debounce(fn: Function, delay: number) {
    let timer: any = null;
    return (...args: any[]) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        // @ts-ignore
        fn.apply(this, args);
      }, delay);
    };
  }