import request from "@/lib/util/request";
import {AccessToken} from "@/lib/type/login-register.type";
import {CartListInterface} from "@/lib/type/cart.type";

// 获取购物车列表
export const requestGetCartList = (params?: AccessToken) => request<CartListInterface>({
    endpoint: "/api/tiny-shop/v1/member/cart-item/index",
    params,
    cache:"no-cache"
});

// 删除购物车
export const requestDeleteCartItem = (ids: number[]) => request({
    endpoint: "/api/tiny-shop/v1/member/cart-item/delete-ids",
    method: "DELETE",
    body: {
        ids: JSON.stringify(ids)
    },
    cache:"no-cache"
});

// 获取购物车总数
export const requestGetCartCount = (params?: AccessToken) => request<string>({
    endpoint: "/api/tiny-shop/v1/member/cart-item/count",
    params,
    cache:"no-cache"
});

// 修改购物车数量
export const requestUpdateCartItemCount = (params: {
    id: number;
    number: number;
}) => request({
    endpoint: "/api/tiny-shop/v1/member/cart-item/update-number",
    method: "POST",
    body: params,
    cache:"no-cache"
});

// 添加购物车
export  const requestAddToCart=(params:{sku_id:string;number:string|number;})=>request({
    endpoint: "/api/tiny-shop/v1/member/cart-item/create",
    method: "POST",
    body: params,
    cache:"no-cache"
})

// 修改购物车sku
export  const requestEditCartSku=(params:{id:number|string;sku_id:string})=>request({
    endpoint:"/api/tiny-shop/v1/member/cart-item/update-sku",
    method:"PUT",
    body:params,
    cache:"no-cache"
})
// 清空购物车
export const requestClearCartList=()=>request({
    endpoint:"/api/tiny-shop/v1/member/cart-item/clear",
    method:"POST",
    cache:"no-cache"
})
