import {create} from "zustand";
import {CartsItemsInterface} from "@/lib/type/cart.type";
import {
    requestClearCartList,
    requestDeleteCartItem,
    requestGetCartCount,
    requestGetCartList,
    requestUpdateCartItemCount
} from "@/lib/api/cart";
import { debounce } from "../util/util";

type CartStore = {
    cartList: CartsItemsInterface[];
    cartTotal: number;
    setCartTotal: () => void;
    cartCount: number;
    setCartList: (list: CartsItemsInterface[]) => void;
    deleteCartList: (sku_id: string) => Promise<void>;
    updateCartItemNumber: (sku_id: string, number: number) => void;
    getCartList: () => Promise<void>;
    getCartCount: () => Promise<void>;
    setCartCount: (count: number) => void;
    cartIdList:number[]
    setCartIdList:()=>void
}

export const useCartStore = create<CartStore>(
    (set, get) => ({
        cartList: [],
        cartTotal: 0,
        cartCount: 0,
        setCartTotal() {
            const list = get().cartList;
            const onSale =list?.length>0 ? list.filter(item => item.product.status === "1") :[];
            const total =onSale?.length>0 ? onSale.reduce((total, item) => {
                return total + (+item.product.number * item.product.price);
            }, 0) : 0;
            set({
                cartTotal: total
            });
        },
        setCartList(list: CartsItemsInterface[]) {
            set({cartList: list});
            get().setCartTotal();
            get().setCartIdList()
                        },
        async deleteCartList(id: string) {
            const res = await requestDeleteCartItem([+id]);
            if (res.code !== 200) return;
            const list = get().cartList.filter(item => item.product.id !== id);
            const countRes=await requestGetCartCount()
            if(countRes.code===200){
                set({cartCount:+countRes.data})
            }
            set({cartList: list});
            get().setCartTotal();
        },
        async deleteAllCartList(){
          const res=await  requestClearCartList()
          if(res.code!==200)return
          set({cartList:[]})
          set({cartTotal:0})
          set({cartCount:0})
        },
        updateCartItemNumber: debounce((id: string, number: number) => {
            get().setCartList(get().cartList.map(item => {
                if (item.product.id === id) {
                    item.product.number = number.toString();
                }
                return item;
            }));
            requestUpdateCartItemCount({
                id: +id,
                number
            });
        }, 200),
        async getCartList() {
            const res = await requestGetCartList();
            if (res.code === 200) {
                const {lose_efficacy} = res.data;
                const list = res.data.carts?.items;
                lose_efficacy.map(lose => {
                    list?.push({
                        product: lose, marketing: [], marketing_explain: "", marketing_tag: "", updated_at: ""
                    });
                });
                get().setCartList(list);
            }
        },
        async getCartCount() {
            const res = await requestGetCartCount();
            if (res.code === 200) {
                get().setCartCount(+res.data);
            }
        },
        setCartCount(count: number) {
            set({cartCount: count});
            get().setCartTotal();
        },
        cartIdList:[],
        setCartIdList(){
            const data = get().cartList.map(item=>+item.product.id)
            set({cartIdList:data})
        },
    })
);

