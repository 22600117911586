import {message} from "antd";
import {useUserStore} from "@/lib/store/user.store";
import {deleteCookie} from "cookies-next";
import {isDev} from "@/lib/util/util";
import {getLoginLink} from "@/lib/util/get-router";

export type RequestCache = "default" | "no-cache" | "reload" | "force-cache" | "only-if-cached" | "no-store";

export interface RequestConfig {
    endpoint: string;
    method?: "GET" | "POST" | "PUT" | "DELETE";
    body?: any;
    headers?: {
        [key: string]: any;
    };
    params?: {
        [key: string]: any;
    };
    baseUrl?: string;
    cache?: RequestCache;
    revalidate?: number;//要按定时间隔重新验证数据，可以使用“fetch”的“next.revalidate”选项来设置资源的缓存生存期（以秒为单位）。
}

export interface RequestResponse<T> {
    code: number;
    message: string;
    data: T;
    timestamp: number;
}

async function request<T>(options: RequestConfig): Promise<RequestResponse<T>> {
    try {
        const {
            endpoint,
            method = "GET",
            cache = "force-cache",
            body,
            headers: customHeaders,
            baseUrl,
            params
        } = options;
        const headers: { [key: string]: string } = {"Content-Type": "application/json", ...customHeaders};
        let url = baseUrl ? `${baseUrl}${endpoint}` : `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;

        if (headers["Content-Type"].includes("multipart/form-data")) {
            delete headers["Content-Type"];
        }

        const config = {
            method,
            headers,
            body: body ? headers["Content-Type"] === "application/json" ? JSON.stringify(body) : body : undefined,
            cache
        };
        const query = params ? JSON.parse(JSON.stringify(params)) : {};
        const userInfo = useUserStore?.getState()?.userInfo;
        if (userInfo && userInfo?.access_token) {
            query["access-token"] = userInfo?.access_token;
        }
        if (query) {
            const queryParameters = Object.keys(query).map(key => `${key}=${query[key]}`).join("&");
            if (queryParameters) {
                url += `?${queryParameters}`;
            }
        }
        const response = await fetch(url, config);
        const contentType = response.headers.get("content-type");
        const isJson = contentType ? contentType.indexOf("application/json") > -1 : false;
        const data: RequestResponse<T> = isJson ? await response.json() : await response.blob();

        // 在接收响应后执行一些操作，例如检查状态码
        if (response.ok && data.code === 200) {
            return data;
        } else if (data.code === 401) {
            if (typeof window !== "undefined") {
                message.error({
                    content: "登录已过期，请重新登录",
                    key: "auth_invalid"
                });
                if (!isDev) {
                    useUserStore.getState().removeUserInfo;
                    deleteCookie("__userStore");
                    window.location.href = getLoginLink();
                }
            }
            return data;
        } else {
            return data;
        }
    } catch (err: any) {
        console.error(err);
        return Promise.reject(err);
    }
}

export default request;
