import {create} from "zustand";
import {persist} from "zustand/middleware";
import {MemberInfoInterface, UserInfoInterface} from "@/lib/type/login-register.type";
import {cookiesStorage} from "@/lib/util/util";
import {useCartStore} from "@/lib/store/cart.store";

export type UserState = {
    remember: boolean;
    setRemember: (value: boolean) => void;
    userInfo: UserInfoInterface | null;
    setUserInfo: (value: UserInfoInterface | null) => void;
    removeUserInfo: () => void;
    setMemberInfo: (value: MemberInfoInterface) => void;
}

export const useUserStore = create(
    persist<UserState>(
        (set, get) => ({
            remember: false,
            setRemember: (value: boolean) => set((state) => ({remember: value})),
            userInfo: null,
            setUserInfo: async (value: UserInfoInterface | null) => {
                const {getCartCount} = useCartStore.getState();
                set((state) => ({userInfo: value}));
                await getCartCount();
            },
            removeUserInfo: () => set((state) => ({userInfo: null})),
            setMemberInfo: (value: MemberInfoInterface) => {
                const userInfo = get().userInfo;
                const info: UserState["userInfo"] = userInfo ? {
                    ...get().userInfo,
                    member: value
                } as UserInfoInterface : null;
                get().setUserInfo(info);
            }
        }),
        {
            name: "__userStore",
            storage: cookiesStorage<UserState>({maxAge: 86400}),
            // 需要持久化存储的key
            partialize: (state: UserState): any => ({
                remember: state.remember,
                userInfo: state.userInfo
            })
        }
    )
);

